/*
 * @Author: your name
 * @Date: 2021-06-21 17:04:03
 * @LastEditTime: 2023-02-09 17:21:21
 * @LastEditors: Pengyue Zhou 1291431328@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \csms-web\src\utils\validator.js
 */
import vueThat from '@/main'
const { Address4, Address6 } = require('ip-address')
let validator = {
  commonNanme: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-20位中文、字母、数字、“_”“-”组合'))
      }
    }
  },
  // 快照名称
  snapNanme: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('快照名称不能为空'))
    } else {
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-20位中文、字母、数字、“_”“-”组合'))
      } else {
        return callback()
      }
    }
  },
  // 用户姓名的校验
  realNanme: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-20位中文、字母、数字、“_”“-”组合'))
      } else {
        return callback()
      }
    }
  },
  rbdNanme: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,32}$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-32位字母、数字、“_”“-”组合'))
      } else {
        // 预留校验是否重名
        return callback()
      }
    }
  },
  // 一致性组名称的校验
  agreGroupName: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      // const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,32}$/gm
      const regexUser = /^[a-zA-Z0-9_-]{1,32}$/gm
      let status = regexUser.test(value)
      let headtailChart, chart = ['_', '-']
      if (chart.includes(value[0]) || chart.includes(value[value.length - 1])) {
        headtailChart = false
      } else {
        headtailChart = true
      }
      if (!headtailChart || !status) {
        return callback(
          new Error('请输入1-32位字母、数字、“_”、“-” 组合，“_”、“-”不能在首尾')
        )
      } else {
        // 预留校验是否重名
        let params = {
          group_name: value
        }
        vueThat.$axios.get(vueThat.$apiUrl.groupNamevalid, { params }).then(res => {
          if (res.status == 200) {
            let isExist = res.data.data.is_exist
            if (isExist == 1) { // 1 已存在 0 不存在
              return callback(new Error('一致性组名已存在'))
            } else {
              return callback()
            }
          }
        }).catch(error => {
          return callback(new Error(error.data.data))
        })
      }
    }
  },
  // 一致性组快照名的校验
  groupSnapName: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/gm
      let status = regexUser.test(value)
      let headtailChart, chart = ['_', '-']
      if (chart.includes(value[0]) || chart.includes(value[value.length - 1])) {
        headtailChart = false
      } else {
        headtailChart = true
      }
      if (!headtailChart || !status) {
        return callback(
          new Error('请输入1-20位中文、字母、数字、“_”、“-” 组合，“_”、“-”不能在首尾')
        )
      } else {
        // 预留校验是否重名
        let params = {
          'snap_name': value
        }
        vueThat.$axios.get(vueThat.$apiUrl.groupSnapNamevalid, {params}).then(res => {
          if (res.status == 200) {
            let isExist = res.data.data.is_exist
            if (isExist == 1) { // 1 已存在 0 不存在
              return callback(new Error('一致性组快照名已存在'))
            } else {
              return callback()
            }
          }
        }).catch(error => {
          return callback(new Error(error.data.data))
        })
      }
    }
  //  }, 400)
  },
  // 虚拟机IP地址校验
  vipFun: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser1 = /^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/
      const regexUser2 = /^([0-9]|[1-9][0-9]|[12][0-4][0-9]|25[0-5])$/
      let arr = value.split('/')
      if (arr.length != 2) {
        return callback(new Error('请输入正确格式的虚拟IP地址'))
      } else {
        let status = regexUser1.test(arr[0])
        let status2 = regexUser2.test(arr[1])
        let status3 = !(arr[1].indexOf('.') > -1)
        if (status && status3 && status2) {
          let params = {
            vip: value,
          }
          vueThat.$axios.get(vueThat.$apiUrl.vipNamevalid, { params }).then(res => {
            if (res.status == 200) {
              let data = res.data.data
              if (data) {
                return callback(new Error('虚拟IP已存在'))
              } else {
                return callback()
              }
            }
          }).catch(error => {
            return callback(new Error(error.data.msg))
          })
        } else {
          return callback(new Error('请输入正确格式的虚拟IP地址'))
        }
      }
    }
  },
  // 验证创建文件网关组ivp6字段
  vip_ipv6Fun: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      let arr = value.split('/')
      const regexUser2 = /^([0-9]|[1-9][0-9]|[12][0-4][0-9]|25[0-5])$/
      if (arr.length != 2) {
        return callback(new Error('请输入正确格式的虚拟IP地址'))
      } else {
        let status2 = regexUser2.test(arr[1])
        let status3 = !(arr[1].indexOf('.') > -1)
        if (status2 && status3) {
          try {
            new Address6(arr[0]);// eslint-disable-line
            let params = {
              vip_ipv6: value,
            }
            vueThat.$axios.get(vueThat.$apiUrl.vipNamevalid, { params }).then(res => {
              if (res.status == 200) {
                let data = res.data.data
                if (data) {
                  return callback(new Error('虚拟IP已存在'))
                } else {
                  return callback()
                }
              }
            }).catch(error => {
              return callback(new Error(error.data.msg))
            })
          } catch (error) {
            return callback(new Error('请输入正确格式的虚拟IP地址'))
          }
        } else {
          return callback(new Error('请输入正确格式的虚拟IP地址'))
        }
      }
    }
  },
  poolNanme: (rule, value, callback) => {
    let poolNameTimer = null
    clearTimeout(poolNameTimer)
    poolNameTimer = setTimeout(() => {
      if (!value) {
        return callback()
      } else {
        const regexUser = /^[a-zA-Z0-9_.-]{1,20}$/
        let status = regexUser.test(value)
        if (!status) {
          return callback(new Error('请输入1-20位字母、数字、“_”“-”“.”组合'))
        } else {
          // 预留校验是否重名
          let params = {
            pool_name: value
          }
          vueThat.$axios.get(vueThat.$apiUrl.poolName, { params }).then(res => {
            if (res.status == 200) {
              let { data } = { ...res.data }
              // console.log('res', data)
              if (data == true) {
                return callback(new Error('存储池名已存在'))
              } else {
                return callback()
              }
            }
          }).catch(error => {
            return callback(new Error(error.data.data))
          })
        }
      }
    }, 400)
  },
  dataReTime: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('时间间隔不能为空'))
    } else {
      // var reg = /^[1-9]+[0-9]*$/
      // 上面正整数
      // const regexUser = /^[1-9]$|^[0-9]{2}$|^[1,2][0-9]{2}$|^[3][0][0]$/
      const regexUser = /^[1-9]+[0-9]*$/
      let status = regexUser.test(value)
      if (!status || value > 1200) {
        return callback(new Error('请输1-1200的正整数'))
      } else {
        // 预留校验是否重名
        return callback()
      }
    }
  },
  // pgViador: (rule, value, callback) => {
  //   console.log(value, 'pgViadorEobricvhufa3eeeeeeeeee')
  //   if (!value) {
  //     return callback()
  //   } else {
  //     vueThat.$axios.get(vueThat.$apiUrl.topoCluster, {}).then(res => {
  //       if (res.status == 200) {
  //         // res.data.topo.totalNum
  //         if (value >= 3 && value <= res.data.topo.totalNum) {
  //           return callback()
  //         } else {
  //           return callback(new Error('请输入3<=k+m <=' + res.data.topo.totalNum))
  //         }
  //       }
  //       console.log('节点数据', res)
  //     })
  //   }
  // },
  valiBucketName: (rule, value, callback) => {
    if (value == '') {
      return callback()
    } else {
      // 验证是否符合规范
      const regexUser = /^[0-9a-z]?[0-9a-z-]{3,20}[0-9a-z]?$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入3-20位小写字母、数字、“-”，“-”不能在首尾。'))
      } else {
        if (value.includes('-')) {
          let valueA = value.split('')
          if (valueA[0] == '-' || valueA[valueA.length - 1] == '-') {
            return callback(new Error('请输入3-20位小写字母、数字、“-”，“-”不能在首尾。'))
          }
        }
        let params = { name: value }
        vueThat.$axios.get(vueThat.$apiUrl.bucketNamevalid, { params })
          .then(data => {
            if (data.status == 200) {
              if (data.data[value]) {
                return callback(new Error('桶名称已存在！'))
              } else {
                return callback()
              }
            }
          }).catch(error => {
            console.log('error', error)
            return callback()
          })
      }
    }
  },
  // objectName: (rule, value, callback) => {
  //   if (!value) {
  //     return callback()
  //   } else {
  //     const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/gm
  //     let status = regexUser.test(value)
  //     if (!status) {
  //       return callback(new Error('请输入1-20位中文、字母、数字、“_”“-”'))
  //     } else {
  //       // 预留校验是否重名
  //       let params = {
  //         filter: 'uid',
  //         uid: value
  //       }
  //       vueThat.$axios.get(vueThat.$apiUrl.objectUser, {params}).then(res => {
  //         if (res.status == 200) {
  //           let {data} = {...res.data}
  //           console.log('res', data)
  //           if (data == true) {
  //             return callback(new Error('对象用户已存在'))
  //           } else {
  //             return callback()
  //           }
  //         }
  //       }).catch(error => {
  //         return callback(new Error(error.data.data))
  //       })
  //     }
  //   }
  // },
  // objectEmail: (rule, value, callback) => {
  //   if (!value) {
  //     return callback()
  //   } else {
  //     const regexEmail = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
  //     let status = regexEmail.test(value)
  //     if (!status) {
  //       return callback(new Error('请输入正确的邮箱格式'))
  //     } else {
  //       vueThat.$axios.get(vueThat.$apiUrl.objectList, {}).then(res => {
  //         if (res.status == 200) {
  //           let dataList = res.data.data.List
  //           let state = false
  //           dataList.forEach(element => {
  //             if(value === element.email){
  //               state = true
  //             }
  //           });
  //           if (state) {
  //             return callback(new Error('邮箱地址已存在'))
  //           } else {
  //             return callback()
  //           }
  //         }
  //       }).catch(error => {
  //         return callback(new Error(error.data.msg))
  //       })
  //     }
  //   }
  // },
  objectKey: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[a-zA-Z0-9_-]{1,50}$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-50位字母、数字、“_”“-”组合'))
      } else {
        // 预留校验是否重名
        return callback()
      }
    }
  },
  alarmInform: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-20位中文、字母、数字、“_”“-”组合'))
      } else {
        // 预留校验是否重名
        let params = {
          valid_type: 1,
          valid_val: value
        }
        vueThat.$axios.get(vueThat.$apiUrl.alarmReceiverName, { params }).then(res => {
          if (res.status == 200) {
            if (res.data.code == 1) {
              return callback(new Error(res.data.msg))
            } else {
              return callback()
            }
          }
        }).catch(error => {
          return callback(new Error(error.data.msg))
        })
      }
    }
  },
  alarmInformMager: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      // const regexUser = /^[\u4e00-\u9fa5-a-zA-Z0-9][\u4e00-\u9fa5-a-zA-Z0-9_-]{1,20}$/gm
      const regexUser = /^[a-zA-Z0-9]{1}([a-zA-Z0-9]|[_-]){0,19}$/
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-20位字母、数字、“_”“-”组合，只能以字母和数字开头'))
      } else {
        // 预留校验是否重名
        let params = {
          valid_type: 2,
          valid_val: value
        }
        vueThat.$axios.get(vueThat.$apiUrl.alarmReceiverName, { params }).then(res => {
          if (res.status == 200) {
            if (res.data.code == 1) {
              return callback(new Error(res.data.msg))
            } else {
              return callback()
            }
          }
        }).catch(error => {
          return callback(new Error(error.data.msg))
        })
      }
    }
  },
  alarmReceUser: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,32}$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-20位中文、字母、数字、“_”“-”组合'))
      } else {
        // 预留校验是否重名
        let data = {
          fieldName: 'recver_name',
          fieldVal: value
        }
        vueThat.$axios.post(vueThat.$apiUrl.alarmReceRule, data).then(res => {
          if (res.status == 200) {
            return callback()
          }
        }).catch(error => {
          return callback(new Error(error.data.msg))
        })
      }
    }
  },
  alarmInformEmail: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexEmail = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      let status = regexEmail.test(value)
      if (!status) {
        return callback(new Error('请输入正确格式的邮箱地址'))
      } else {
        // 预留校验是否重名
        let data = {
          fieldName: 'mail',
          fieldVal: value
        }
        vueThat.$axios.post(vueThat.$apiUrl.alarmReceRule, data).then(res => {
          if (res.status == 200) {
            return callback()
            // if (res.data.code == 1) {
            //   return callback(new Error(res.data.msg))
            // } else {
            //   return callback()
            // }
          }
        }).catch(error => {
          if (error.data.msg) {
            return callback(new Error(error.data.msg))
          } else {
            return callback()
          }
        })
      }
    }
  },
  alarmInformIP: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入正确格式的IP地址'))
      } else {
        // 预留校验是否重名
        let params = {
          valid_type: 3,
          valid_val: value
        }
        vueThat.$axios.get(vueThat.$apiUrl.alarmReceiverName, { params }).then(res => {
          if (res.status == 200) {
            if (res.data.code == 1) {
              return callback(new Error(res.data.msg))
            } else {
              return callback()
            }
          }
        }).catch(error => {
          return callback(new Error(error.data.msg))
        })
      }
    }
  },
  alarmInformKey: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[a-zA-Z0-9_-]{8,20}$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入8-20个字母、数字、“-”、“_”组合'))
      } else {
        return callback()
        // 预留校验是否重名
      }
    }
  },
  alarmInformUrl: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^((http|https):\/\/)(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])){3}(:([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]))?([:|/|_|.]([:_/.a-z\d]*))?$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入正确格式的URL'))
      } else {
        // 预留校验是否重名
        return callback()
      }
    }
  },
  alarmEmailServer: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入名称'))
    } else {
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-20位中文、字母、数字、“_”“-”组合'))
      } else {
        // 预留校验是否重名
        let data = {
          server_name: value
        }
        vueThat.$axios.post(vueThat.$apiUrl.alarmReceiverCheckName, data).then(res => {
          if (res.status == 200) {
            if (res.data.code == 1) {
              return callback(new Error(res.data.msg))
            } else {
              return callback()
            }
          }
        }).catch(error => {
          return callback(new Error(error.data.msg))
        })
      }
    }
  },
  alarmEmailCode: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入授权码'))
    } else {
      const regexUser = /^[A-Za-z]{14,18}$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入14-18位字母'))
      } else {
        // 预留校验是否重名
        return callback()
      }
    }
  },
  alarmEmailSmtp: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入smtp服务器地址'))
    } else {
      // const regexUser = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
      const regexUser = /^(smtp)\.[a-zA-Z0-9]+\.(com)?$/
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入正确格式的smtp服务器地址'))
      } else {
        // 预留校验是否重名
        return callback()
      }
    }
  },
  // ip校验
  Iqn: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-50位中文、字母、数字、“_”“-”组合'))
      } else {
        // 预留校验是否重名
        return callback()
      }
    }
  },
  // 启动器规则校验
  clientIqn: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /iqn\.[0-9]{4}-[0-1][0-9]\..*\..*/
      let status = regexUser.test(value)
      let statusS1 = value.includes(' ')
      let statusS2 = value.includes('_')
      let statusS3 = value.includes('=')
      if (!status || statusS1 || statusS2 || statusS3) {
        return callback(new Error('请输入1-223 位字母、数字、“-”“：”“.”组合'))
      } else {
        // 预留校验是否重名
        vueThat.$axios.get(vueThat.$apiUrl.clientNameiqnvalid + '?iqn=' + value).then(res => {
          if (res.data.data == true) {
            return callback(new Error('启动器已存在'))
          } else {
            return callback()
          }
        }).catch(error => {
          return callback(new Error(error.data.msg))
        })
      }
    }
  },
  // 公共校验ip是否正确
  IPValidotor: function (rule, value, callback) {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入正确格式的IP地址'))
      } else {
        // 校验是否重复
        // ps: 增加可复用性
        // 重定向的对象里面的校验函数名必须是apiVerify
        this.apiVerify(value, callback)
      }
    }
  },
  // 公共校验ip是否正确
  IPv6Validotor: function (rule, value, callback) {
    if (!value) {
      return callback()
    } else {
      const regexUser = /([0-9a-z]*:{1,4}){1,7}[0-9a-z]{1,4}/g
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入正确格式的IP地址'))
      } else {
        // 校验是否重复
        // ps: 增加可复用性
        // 重定向的对象里面的校验函数名必须是apiVerify
        this.apiVerify(value, callback)
      }
    }
  },
  // 更灵活的校验模板
  validatorTemplate: function (rule, value, callback) {
    if (!value) {
      return callback()
    } else {
      let regexUser = this.regexText
      let status = regexUser.test(value)
      regexUser.lastIndex = 0
      if (!status) {
        return callback(new Error(this.regexMsg))
      } else {
        // 校验是否重复
        // ps: 增加可复用性
        // 重定向的对象里面的校验函数名必须是apiVerify
        this.apiVerify(value, callback)
      }
    }
  },
  // 多集群管理-集群别名验证
  clusterAliasName: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('输入集群别名'))
    } else {
      // 验证是否符合规范
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9-]{1,20}?$/gm
      let status = regexUser.test(value)
      if (!status) {
        return callback(new Error('请输入1-20位中文、字母、数字、“-”，“-”不能在首尾。'))
      } else {
        if (value.includes('-')) {
          let valueA = value.split('')
          if (valueA[0] == '-' || valueA[valueA.length - 1] == '-') {
            return callback(new Error('请输入1-20位中文、字母、数字、“-”，“-”不能在首尾。'))
          }
        }
        let params = { cluster_alias_name: value }
        vueThat.$axios.post(vueThat.$apiUrl.checkClusterName, params)
          .then(data => {
            if (data.status == 200) {
              if (data.data.data) {
                return callback(new Error('集群别名已存在！'))
              } else {
                return callback()
              }
            }
          }).catch(error => {
            console.log('error', error)
            return callback()
          })
      }
    }
  },
  clusterIp: (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入IP地址'))
    } else {
      try {
        new Address4(value);// eslint-disable-line
        return callback()
      } catch (error) {
        return callback(new Error('请输入正确格式的IP地址！'))
      }
    }
  },
  // 文件用户校验
  fileUserName: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{3,32}$/gm
      let status = regexUser.test(value)
      let headtailChart
      let chart = ['_', '-']
      if (chart.includes(value[0]) || chart.includes(value[value.length - 1])) {
        headtailChart = false
      } else {
        headtailChart = true
      }
      if (!headtailChart || !status) {
        return callback(
          new Error('请输入3-32位字母、数字、“_”、“-” 组合，“_”、“-”不能在首尾')
        )
      } else {
        return callback()
      }
    }
  },
  // 文件用户组名称校验
  fileUserGroupName: (rule, value, callback) => {
    if (!value) {
      return callback()
    } else {
      const regexUser = /^[a-zA-Z0-9_-]{3,20}$/gm
      let status = regexUser.test(value)
      let headtailChart
      let chart = ['_', '-']
      if (chart.includes(value[0]) || chart.includes(value[value.length - 1])) {
        headtailChart = false
      } else {
        headtailChart = true
      }
      if (!headtailChart || !status) {
        return callback(
          new Error('请输入3-20位字母、数字、“_”、“-” 组合，“_”、“-”不能在首尾')
        )
      } else {
        return callback()
      }
    }
  },
}
// 传参
let verifyInterface = {
  // 客户端主机IP校验信息
  client: {
    apiVerify: function (val, callback) {
      let params = {
        ip: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.clientIpvalidt, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('IP已存在'))
          } else {
            return callback()
          }
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 客户端主机IPv6校验信息
  clientv6: {
    apiVerify: function (val, callback) {
      let params = {
        ipv6: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.clientIpvalidtv6, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('IP已存在'))
          } else {
            return callback()
          }
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 客户端主机
  clientHostName: {
    regexText: /^[a-zA-Z0-9-_.]{1,32}$/gm, // 正则
    regexMsg: '请输入1-32位字母、数字、“_”“-”“.”组合。', // 正则校验错误提示语
    apiVerify: function (val, callback) {
      let params = {
        name: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.clientNamevalid, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('客户端主机名称已存在'))
          } else {
            return callback()
          }
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 客户端主机组
  clientHostGroup: {
    // regexText: /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/gm, // 正则
    // regexMsg: '请输入1-20位中文、字母、数字、“_”“-”组合。', // 正则校验错误提示语
    regexText: /^[@_a-zA-Z0-9_-]{1,32}$/gm,
    regexMsg: '请输入1-32位字母、数字、“_”、“-”、"@"组合',
    apiVerify: function (val, callback) {
      let params = {
        name: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.clientGroupNamevalid, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('客户端主机组名称已存在'))
          } else {
            return callback()
          }
        } else {
          return callback()
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 用户管理-创建用户-用户名校验
  userNameData: {
    //  regexText: /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/gm,
    // regexMsg: '请输入1-20位中文、字母、数字、“_”“-”组合',
    regexText: /^[a-zA-Z0-9_-]{1,20}$/,
    regexMsg: '请输入1-20位字母、数字、“_”“-”组合',
    apiVerify: function (val, callback) {
      let params = {
        name: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.userNameValid, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('用户名称已存在'))
          } else {
            return callback()
          }
        } else {
          return callback()
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 用户名称
  userName: {
    regexText: /^[^\u4e00-\u9fa5]{6,28}$/gm, // 正则
    regexMsg: '请输入6-28位密码，必须包含大小写字母、数字、特殊字符', // 正则校验错误提示语
    apiVerify: function (val, callback) {
      let params = {
        name: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.userNameValid, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('用户名称已存在'))
          } else {
            return callback()
          }
        } else {
          return callback()
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 映射关系名称校验
  mapName: {
    regexText: /^[0-9a-z-.]{1,20}$/gm, // 正则
    regexMsg: '请输入1-20位小写字母、数字、“-”、“.”组合', // 正则校验错误提示语
    apiVerify: function (val, callback) {
      let params = {
        name: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.mapNamevalid, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('映射关系名称已存在'))
          } else {
            return callback()
          }
        } else {
          return callback()
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 目标端认证名
  mapUsername: {
    regexText: /^[0-9a-zA-Z-_]{8,26}$/gm, // 正则
    // regexMsg: '请输入8-26位大小写字母、数字、“-”“_”组合', // 正则校验错误提示语
    regexMsg: '请输入8-26位字母、数字、“_”“-”组合', // 正则校验错误提示语
    apiVerify: function (val, callback) {
      return callback()
    },
  },
  // 目标端密码
  mapPassword: {
    regexText: /^[0-9a-zA-Z-_]{12,16}$/gm, // 正则
    regexMsg: '请输入12-16位字母、“_”“-”组合', // 正则校验错误提示语
    apiVerify: function (val, callback) {
      return callback()
    },
  },
  // 用户新密码校验
  userNewPassword: {
    // regexText: /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,28}$/, // 正则
    // regexMsg: '请输入6-28位密码，必须包含大小写字母、数字、特殊字符', // 正则校验错误提示语

    // 字符包括：”_ ”、 “- ”、“ ." 、"@"、"% "、"&"、" $"、" , "、"; "、"="、" + "、"—"、" ? "、"。"、"!"、"… "、"* "、"（ "、"）"、" ~"、" · "、"、"、"<"、">"、" 《"、"》"、" [ "、"]"、"  { "、"}"
    regexText: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[-_.-.@%&$,;=+—?。!…*~·、（）<>《》{}[\]])[0-9a-zA-Z-_.-.@%&$,;=+—?。!…*~·、（）<>《》{}[\]]{6,28}$/, // 正则
    // regexText: /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[0-9]).{6,28}$/, // 正则
    regexMsg: '请输入6-28位字母、数字、符号组合', // 正则校验错误提示语
    apiVerify: function (val, callback) {
      return callback()
    },
  },
  // 文件用户密码校验
  fileUserPassword: {
    regexText: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[-!.#.$%&()*+,-./:;<=>?@[\\^`{_|\]}~])[0-9a-zA-Z-!.#.$%&()*+,-./:;<=>?@[\\^`{_|\]}~]{8,24}$/, // 正则
    regexMsg: '请输入8-24位密码，必须包含大小写字母、数字、特殊字符', // 正则校验错误提示语
    apiVerify: function (val, callback) {
      return callback()
    },
  },
  // 文件存储客户端的ip校验
  fileClient: {
    apiVerify: function (val, callback) {
      let params = {
        ip_addr: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.fileClientIpvalid, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('IP已存在'))
          } else {
            return callback()
          }
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 文件存储客户端主机的名称校验
  fileClientHostName: {
    regexText: /^[a-zA-Z0-9-_.]{1,32}$/gm, // 正则
    regexMsg: '请输入1-32位字母、数字、“_”“-”“.”组合', // 正则校验错误提示语
    apiVerify: function (val, callback) {
      let params = {
        name: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.fileClientNamevalid, { params }).then(res => {
        console.log(res, '查看用户名是否重名')
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('客户端主机名称已存在'))
          } else {
            return callback()
          }
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 文件客户端主机组名称校验接口
  fileClientHostGroup: {
    // regexText: /^[\u4e00-\u9fa5_a-zA-Z0-9_-]{1,20}$/gm, // 正则
    // regexMsg: '请输入1-20位中文、字母、数字、“_”“-”组合。', // 正则校验错误提示语
    regexText: /^[@_a-zA-Z0-9_-]{1,32}$/gm,
    regexMsg: '请输入1-32位字母、数字、“_”“-”“@”组合',
    apiVerify: function (val, callback) {
      let params = {
        name: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.fileClientGroupNamevalid, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('客户端主机组名称已存在'))
          } else {
            return callback()
          }
        } else {
          return callback()
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  // 文件网关组名称的校验
  filegatewayGroup: {
    // a-zA-Z0-9-_.
    regexText: /^[a-zA-Z0-9_-]{1,20}$/gm, // 正则
    regexMsg: '请输入1-20位字母、数字、“_”“-”组合', // 正则校验错误提示语
    apiVerify: function (val, callback) {
      let params = {
        name: val,
      }
      vueThat.$axios.get(vueThat.$apiUrl.gatewayGroupNamevalid, { params }).then(res => {
        if (res.status == 200) {
          let { data } = res.data
          if (data) {
            return callback(new Error('文件网关组名称已存在'))
          } else {
            return callback()
          }
        } else {
          return callback()
        }
      }).catch(error => {
        return callback(new Error(error.data.msg))
      })
    },
  },
  IPv6ValidotorRule: function (value) {
    const regexUser = /([0-9a-z]{1,4}:){7}/g
    let status = regexUser.test(value)
    let statusx = false
    if (!status) {
      if (value.split(':').length !== 8) {
        if (value.split(':').length > 8) {
          statusx = true
        } else {
          if (!value.includes('::')) {
            statusx = true
          } else {
            if (value.includes(':::')) {
              statusx = true
            } else if (value.includes('::')) {
              if (value.split('::').length != 2) {
                statusx = true
              } else {
                if (value.split('::')[0].length == 0 && value.split('::')[1].length == 0) {
                  statusx = true
                }
              }
            } else {
              statusx = true
            }
          }
        }
      } else {
        if (value.includes('::')) {
          statusx = true
        }
      }
    } else {
      if (value.split(':').length > 8) {
        statusx = true
      } else {
        statusx = false
      }
    }
    if (statusx) {
      return false
    } else {
      // 校验是否重复
      // ps: 增加可复用性
      // 重定向的对象里面的校验函数名必须是apiVerify
      return true
    }
  },
  ipAddress: function (value) {
    let valArr = value.split(',')
    let state = true
    for (let i = 0; i < valArr.length; i++) {
      try {
        new Address4(valArr[i]);// eslint-disable-line
      } catch (error) {
        try {
          new Address6(valArr[i]);// eslint-disable-line
        } catch (error) {
          state = false
        }
      }
    }
    return state
  },
}

export { validator, verifyInterface }
