var simpleUnit = {
  // 当前视口宽度
  nowClientWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  initWidth: 1920,
  setWidth (val) {
    this.nowClientWidth = val
  },
  nowSize (val) {
    // 换算方法
    // console.log('this.nowClientWidth / this.initWidth', this.nowClientWidth / this.initWidth)
    return val * (this.nowClientWidth / this.initWidth)
  },
  nowSizeArray (val) {
    // console.log('this.nowClientWidth / this.initWidth', this.nowClientWidth / this.initWidth)
    let newArr = val.map(item => {
      return item * (this.nowClientWidth / this.initWidth)
    })
    // console.log('数组', newArr)
    return newArr
  },
  listenResize (domInit) {
    window.addEventListener('resize', function () {
      // domInit.setOption(option)
      domInit.resize()
      // location.reload()
    }, false)
  },
  // 校验必输项是否全部输入
  checkRequired (form, field) {
    console.log(field)
    let status = true
    for (var i = 0; i < field.length; i++) {
      if (field[ i ] == undefined) {
        return false
      } else {
        let type = typeof (form[field[ i ]])
        type = type == 'object' ? form[field[ i ]] instanceof Array ? 'array' : form[field[ i ]] instanceof Object ? 'object' : 'undefined' : type
        switch (type) {
          case 'string':
            status = form[field[ i ]] === ''
            break
          case 'array':
            status = form[field[ i ]].length == 0
            break
          case 'object':
            status = Object.keys(form[field[ i ]]).length == 0
            console.log('Object.keys(form[field[ i ]]).length', Object.keys(form[field[ i ]]).length)
            break
          case 'number':
          case 'boolean':
            status = false
            break
          case 'undefined':
            status = true
            break
        }
        if (status) {
          return status
        }
      }
    }
    return status
  },
  // input number 校验 参数 value（input值） maxLength（input最大值）
  inputVerify: function (value, maxLenght, minLenght) {
    value = Math.trunc(value)
    if (maxLenght) {
      if (value > maxLenght) {
        value = maxLenght
      }
    }
    if (minLenght) {
      if (value < minLenght) {
        value = minLenght
      }
    }
    if (!value) {
      value = 1
    }
    return value
  },
}
export default simpleUnit
