<!--
 * @Author: your name
 * @Date: 2021-05-11 14:54:41
 * @LastEditTime: 2023-02-20 17:12:34
 * @LastEditors: Pengyue Zhou 1291431328@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \CSMS-Web\src\App.vue
-->
<template>
  <div id="app">
    <div class='container'>
      <router-view/>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
#app{
  height: 100%;
}
.el-main {
  overflow: hidden !important;
}
</style>
