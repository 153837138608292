/*
 * @Author: sgf
 * @Date: 2021-05-11 15:27:25
 * @LastEditTime: 2023-03-22 21:24:45
 * @LastEditors: Pengyue Zhou 1291431328@qq.com
 * @Description: axiox 公共
 * @FilePath: \CSMS-Web\src\utils\request.js
 */
/**
 * ctrl+alt+t
 * @description: axios公共请求方法
 * @param {*}
 * @return {*}
 */
import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'

// axios 配置
axios.defaults.timeout = 50000 // 超时时间为50s
axios.defaults.baseURL = process.env.VUE_APP_SERVER_IP

// 请求拦截器
axios.interceptors.request.use(config => {
  config.headers['X-Access-Token'] = localStorage.getItem('token') ? localStorage.getItem('token') : ''
  config.headers['Content-Type'] = 'application/json;charset=UTF-8'
  return config
}, error => {
  console.log('请求过期', error)
  return Promise.reject(error)
})
// 响应拦截器
axios.interceptors.response.use(response => {
  if (response && response.data.code === 200) {
    return response
  }
  // 考试状态
  if (response && response.data.code === 500 && (response.config.url == '/student/exam/entryExam' || response.config.url == '/student/exam/getStuExam')) {
    return response
  }
  if (response.data.code == 401) { // 未登录
    // Message({
    //   type: 'error',
    //   message: '数据请求失败，请先登录！'
    // })
    router.replace({
      path: '/login'
    })
  } else {
    Message({ // 其他错误
      type: 'error',
      message: response.data.msg
    })
  }
}, error => {
  console.log(error, 1223)
  // if (error.response.status === 401) {
  //   Notification.error({title: '系统提示', message: '用户登录信息失效，请重新登录！'})
  // } else {
  //   Notification.error({title: '系统提示', message: error.response.message || error.response.msg})
  // }
  return Promise.reject(error.response)
})

// 创建实例
axios.create({
})
export default axios
