/*
 * @Author: your name
 * @Date: 2021-05-11 14:54:41
 * @LastEditTime: 2023-02-20 19:08:44
 * @LastEditors: Pengyue Zhou 1291431328@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \CSMS-Web\src\main.js
 */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
// axios
import axios from '@/utils/request'
// element
import ElementUI from 'element-ui'
// 适配
// import 'amfe-flexible'
import 'amfe-flexible/index.js'
// 公共方法
import simpleUnit from '@/utils/publicFun'
// 公共样式
import '@/utils/allCss.js'
import Modal from '@/components/Base/Modal'
// table详情弹窗
// 校验规则
import { validator, verifyInterface } from '@/utils/validator'
// 定义常量
// 定义常量:url常量
import apiUrl from '@/api/apiUrl'

import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)  css路径
// import 'swiper/swiper-bundle.css'

// import style (<= Swiper 5.x)  css路径
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// 注册
Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.prototype.$validator = validator
Vue.prototype.$verifyInterface = verifyInterface
Vue.prototype.$apiUrl = apiUrl
// 遍历require.context的返回模块，并导入
const requireAll = requireContext => requireContext.keys().map(requireContext)
// import所有符合条件的svg 三个参数：文件夹、是否使用子文件夹、正则
const req = require.context('@/assets/icons/svg', true, /\.svg$/)
requireAll(req)

// 全局注册模态框
Vue.component('modal', Modal)
// 公共方法
Vue.prototype.$simpleUnit = simpleUnit

// 生产消息
Vue.config.productionTip = false

/* eslint-disable no-new */
let vueThat = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

export default vueThat
